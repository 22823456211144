<template>
	<div class='logo-header'>
		<!-- <img src='/logo.svg'> -->
		<img src='/logo.png'>
	</div>
</template>
<script>
	export default {
		name: 'AccessScreen'
	}
</script>
<style>
	.logo-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 15vh;
		/*padding: 16px 0;*/
		
		display: flex;
		justify-content: center;
		align-items: center;
		max-height: 15vh;

	}

	.logo-header img {
		height: 10vh;
		max-height: 48px;
		object-fit: contain;
	}
</style>