<template>
	<div class='screen intro-screen'>
		<LogoHeader/>
		<div class='intro-container'>
			<div>
				<img id='intro-icon' src='/intro-picture.svg'>
			</div>
			<div>
				<div id='slogan-container'>
					<h1>Electrify your home!</h1>
					<p>
						Learn about the electric load in your home and what you could do to make it more sustainable with our Panel scanning tool.
					</p>
				</div>
			</div>
			<div class='button-container'>
				<button id='start-btn' v-on:click='start()'>Start</button>
			</div>
		</div>
	</div>	
</template>
<script>
	import LogoHeader from './LogoHeader.vue'

	export default {
		name: 'IntroScreen',
		components : {LogoHeader},
		methods : {
			start : function () {
				this.$emit('started', true)
			}
		}
		
	}
</script>
<style>
	.screen.intro-screen {
		flex-direction: column;
		justify-content: space-between;
		padding: 16px;
	}

	.intro-container {
		width: 100vw;
		height: 85vh;
		position: fixed;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 32px 32px 0 0;
		padding: 24px;
	}

	.intro-container div {
		padding: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;	
	}

	#slogan-container {
		text-align: center;
		width: 45vw;
		padding-bottom: 20vh;
	}

	#slogan-container h1 {
		font-size: 1.8rem;
	}

	#slogan-container p {
		font-size: 1.2rem;
	}

	#intro-icon {
		max-height: 10vh;
	}

	@media screen and (max-width: 480px) {
		.intro-container {
			height: 85dvh;
		}
		#slogan-container  {
			width: 70vw;
			padding-bottom: 20dvh;
		}
	}
</style>