<template>
	<div :id='id' class='screen image-selection-screen'>
		<div v-if='state == "picture-choice"' class='instructions'>
			<div>
				<p>Take or upload a picture of the entire panel</p>
			</div>
		</div>
		<div v-if='state == "picture-confirmation"' class='instructions'>
			<div>
				<p>If it's okay, click the send button</p>
			</div>
		</div>
		<div>
			<div>
				<canvas id='image-preview' ref='imagePreview'></canvas>
			</div>
		</div>

		<div id='fake-btn' class='button-container'>
			<!-- FIX FILEUPLOADER WHEN CHANGING PICTURE -->
			<FileUploader id='uploader' ref='uploader' v-on:click='setState' @image='previewImage'/>
			<button v-if='state == "picture-confirmation"' v-on:click='convertPicture()' id='preview-send-btn'>send</button>
		</div>
	</div>
</template>
<script>
	import FileUploader from './FileUploader.vue'
	import {LZWEncoder, NeuQuant, GIFEncoder, encode64} from '../assets/GIFEncoder.js'

	export default {
		name: 'ImageSelectionScreen',
		components : {
			FileUploader
		},
		props : ['id'],
		data () {
			return {
				state : 'picture-choice', // ['picture-choice', 'picture-confirmation']
				stream : null,
				imageData : null,
				GIFdata : null
			}
		},
		watch : {
			state : {
				flush : 'post',
				handler (state) {
					switch (state) {
						case ('picture-confirmation'):
							let canvas = this.$refs.imagePreview,
								context = canvas.getContext('2d')

							let img = new Image()
							
							img.onload = function () {
								let w = img.width,
									h = img.height,
									ratio = w / h

								//let cHeight = window.innerHeight * 0.75,
								let cHeight = window.innerHeight * 0.7,
									cWidth = cHeight * ratio

								if (cWidth > (window.innerWidth - 32)) {
									cWidth = window.innerWidth - 32
									w > h ? cHeight = (cWidth * ratio) : cHeight = (cWidth / ratio) 
 
								}

								canvas.width = cWidth
								canvas.height = cHeight
								context.drawImage(img, 0, 0, cWidth, cHeight)
							}

							img.src = this.imageData
							break
						default : 
							break
					}
				}
			},
			GIFdata : {
				handler (GIFdata) {
					this.$emit('image', this.GIFdata)
					this.$emit('imagePNG', this.imageData)
				}
			}
		},
		methods : {
			isMobile : function () {
				if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					return true
				} else {
					return false
				}
			},
			setState : function () {
				this.state = 'picture-choice'
			},
			previewImage : function (imageData) {
				this.imageData = imageData
				this.state = 'picture-confirmation'
			},
			convertPicture : function () {
				let canvas = this.$refs.imagePreview,
					context = canvas.getContext('2d')

				let img = new Image(), 
					that = this
				
				img.onload = function() {
					canvas.width = img.width
					canvas.height = img.height
					context.drawImage(img, 0, 0)

					let encoder = new GIFEncoder()
					encoder.start()
					encoder.addFrame(context)
					encoder.finish()

					let GIFdata = encoder.stream().getData()
	  				that.GIFdata = encode64(GIFdata)
				}
				img.src = this.imageData
			}
		}
	}
</script>
<style>
	#uploader {
		width: 20vw;
	}

	#fake-btn {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		position: fixed !important;
		width: 100vw;
		bottom: 32px !important;
		left: 0;
	}

	@media screen and (max-width: 900px) {
		#uploader  {
			width: 40vw;
		}
	}
</style>