<template>
	<div :id='id' class='report-entry'>
		<div class='group-title-container'>
			<h3>{{ name }}</h3>
		</div>
		<div>
			<ul>
				<li v-for='(amp, key) in amperages'>{{ amp.amperage }} A</li>
			</ul>
		</div>
		<div>
			<p><strong>total: {{ total }} A</strong></p>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'ReportEntry',
		props : ['id', 'name', 'amperages'],
		data () {
			return {
				total : null
			}
		},
		mounted () {
			console.log(this.name, this.amperages)
			let totalAmp = 0
			for (let i = 0; i < this.amperages.length; i ++) {
				let amp = this.amperages[i].amperage
				totalAmp += parseInt(amp)
			}
			this.total = totalAmp
		},
		methods : {
		}
	}
</script>
<style>
	h3 {
		color: #FFF;
	}

	ul {
		list-style-type: none
	}

	li {
		padding: 4px;
		border-bottom: 1px solid var(--dark-blue);
	}

	li:last-child {
		margin-bottom: 4px;
		border-bottom: 2px solid var(--dark-blue);
	}

	.report-entry {
		width: 30vw;
		padding: 16px 0;
	}

	.group-title-container {
		background-color: var(--light-blue);
		padding: 4px;
	}

	@media screen and (max-width: 480px) {
		.report-entry {
			width: 60vw;
		}
	}
</style>