<template>
	<div :id='id' class='screen image-annotation-screen'>
		<div v-if='annotation == null' class='instructions'>
			<div>
				<p>Analysing the image…</p>
			</div>
		</div>
		<div v-if='annotation != null' class='instructions'>
			<div>
				<p>Here is what we found</p>
			</div>
		</div>
		<canvas id='annotation-canvas' ref='annotationCanvas'></canvas>
	</div>
	<div id='labels-container'>
		<div id='labels' ref='labelsDiv' :style='`width:${imgWidth * widthRatio}px; height:${imgHeight * heightRatio}px; `'>
			<OCRLabel v-for='(block, key) in blocks' 
				:id='"block-" + block.id' 
				:x='block.p0[0]' 
				:y='block.p0[1]' 
				:center = 'block.center'
				:width='block.width' 
				:height='block.height' 
				:text='block.text' 
				:type='block.type'
				:group='block.group'
			/>
		</div>
	<div v-if='groupedBlocks != null' class='button-container'>
		<button v-on:click='changeState()'>See the report</button>
	</div>
	</div>
	<!-- <a v-if='groupedBlocks != null' id='json-downloader' ref='JSONDowloader'>
		<button id='json-download-btn'>Download JSON</button>
	</a> -->
</template>
<script>
	import * as axios from 'axios'
	import OCRLabel from './OCRLabel.vue'

	export default {
		name: 'ImageAnnotationScreen',
		components : {
			OCRLabel
		},
		props : ['id', 'image', 'png'],
		data () {
			return {
				annotation : null,
				imgWidth : null,
				imgHeight : null,
				widthRatio : null,
				heightRatio : null,
				rawBlocks : null,
				blocks : null,
				amperageBlocks : [],
				descriptionBlocks : [],
				groupedBlocks : null,
				windowWidth : window.innerWidth,
				windowHeight: window.innerHeight,
				canvasX : null,
				canvasY : null
			}
		},
		watch : {
			imgHeight : {
				handler (imgHeight) {
					this.$refs.labelsDiv.setAttribute('style', `width : ${this.imgWidth * this.widthRatio}px; height : ${this.imgHeight * this.heightRatio}px; `)
					this.annotate()
				}
			},
			annotation : {
				handler (annotation) {
					if (annotation[0].fullTextAnnotation != null) {
						let annotatedBlocks = annotation[0].fullTextAnnotation.pages[0].blocks
						this.rawBlocks = this.cleanData(annotatedBlocks)
					}
				}
			},
			rawBlocks : {
				handler (rawBlocks) {
					this.blocks = this.verifyBlocks(rawBlocks)
				}
			},
			blocks : {
				flush : 'post',
				handler (blocks) {
					if (blocks.length > 0) {
						//this.panel = {'blocks' : blocks}
						this.panel = this.groupedItems
						this.linkBlocks()
							
					}
				}
			}
		},
		mounted () {
			let annotatedCanvas = this.$refs.annotationCanvas,
				context = annotatedCanvas.getContext('2d')

			let img = new Image()

			let that = this

			img.onload = function() {
				let w = img.width,
					h = img.height,
					ratio = w / h

				/*let cHeight = window.innerHeight * 0.75,
					cWidth = cHeight * ratio*/

				let cHeight = window.innerHeight * 0.7,
					cWidth = cHeight * ratio

				if (cWidth > (window.innerWidth - 32)) {
					cWidth = window.innerWidth - 32
					w > h ? cHeight = (cWidth * ratio) : cHeight = (cWidth / ratio) 
				}


				that.imgWidth = img.width
				that.imgHeight = img.height

				that.widthRatio = cWidth / w
				that.heightRatio = cHeight / h

				annotatedCanvas.width = cWidth
				annotatedCanvas.height = cHeight
				context.drawImage(img, 0, 0, cWidth, cHeight)
			}
			
			img.src = this.png

			let canvasPos = annotatedCanvas.getBoundingClientRect()
			console.log(canvasPos)
			this.canvasX = canvasPos.left
			this.canvasY = canvasPos.top

		},
		methods : {
			annotate : async function () {
				let response = await axios.post('https://moixa-api.herokuapp.com/api/send', {file : this.image, timeout : 10000000})
				this.annotation = response.data
			},
			cleanData : function (rawBlocks) {
				console.log('cleaning data')
				let cleanBlocks = [],
					amperageBlocks = [],
					textBlocks = [],
					numberTextBlocks = [],
					textNumberBlocks = []
				
				for (let idx in rawBlocks) {
					let rawBlock = rawBlocks[idx]

					let paragraphs = rawBlock.paragraphs,
						bb = rawBlock.boundingBox['vertices'],
						p0 =  [bb[0].x * this.widthRatio, bb[0].y * this.heightRatio],
						p1 =  [bb[1].x * this.widthRatio, bb[1].y * this.heightRatio],
						p2 =  [bb[3].x * this.widthRatio, bb[3].y * this.heightRatio],
						p3 =  [bb[2].x * this.widthRatio, bb[2].y * this.heightRatio],
						width = Math.max(p1[0] - p0[0], 20),
						height = Math.max(p2[1] - p0[1], 20),
						text = this.getText(paragraphs)

					let block = {
						'p0' : p0,
						'width' : width,
						'height' : height,
						'text' : text, 
						'type' : null
					}


					if (/^\s*\d+\s*$/.test(text) && parseInt(text) % 5 == 0 && parseInt(text) <= 90) {
						block.type = 'amperage'
						amperageBlocks.push(block)
					} else if (/^\d+\s+.+$/.test(text)) {
						numberTextBlocks.push([rawBlock, block])	
					} else if (/^.+\s+\d+$/.test(text)) {
						textNumberBlocks.push([rawBlock, block])	
					} else if (/^[^\d]+$/.test(text)) {
						block.type = 'description'
						textBlocks.push(block)
					} 
				}

				for (let idx in numberTextBlocks) {
					let rawBlock = numberTextBlocks[idx][0],
						block = numberTextBlocks[idx][1],
						originalText = block.text

					// get index of first letter
					let splitIdx = originalText.search(/\s[A-Za-z]/), 
						number = originalText.substring(0, splitIdx), 
						text = originalText.substring(splitIdx+1, originalText.length),
						words = rawBlock.paragraphs[0].words,
						numberWidth = 0,
						numberHeight = 0

					
					let numberBlock = words[0], 
						bbNumber = numberBlock.boundingBox['vertices'],
						p0Number =  [bbNumber[0].x, bbNumber[0].y],
						p1Number =  [bbNumber[1].x, bbNumber[1].y],
						p2Number =  [bbNumber[3].x, bbNumber[3].y],
						p3Number =  [bbNumber[2].x, bbNumber[2].y]
					numberWidth = Math.max(p1Number[0] - p0Number[0], 24)
					numberHeight = Math.max(p2Number[1] - p0Number[1], 24)

					block = {
						'p0' : p0Number,
						'width' : numberWidth,
						'height' : numberHeight,
						'text' : number, 
						'type' : 'amperage'
					}

					// verify if the number is useful
					if (parseInt(text) % 5 == 0 && parseInt(text) <= 90) {
						amperageBlocks.push(block)
					}

					// create block for the description
					block = {
						'p0' : [block.p0[0] + numberWidth, block.p0[1] + numberHeight],
						'width' : block.width - numberWidth,
						'height' : block.height - numberHeight,
						'text' : text, 
						'type' : 'description'
					}

					textBlocks.push(block)
				}

				// textNumberBlocks
				for (let idx in textNumberBlocks) {
					let rawBlock = textNumberBlocks[idx][0],
						block = textNumberBlocks[idx][1],
						originalText = block.text

					// get index of first letter
					let splitIdx = originalText.search(/\s\d/), 
						number = originalText.substring(splitIdx + 1, originalText.length), 
						text = originalText.substring(0, splitIdx),
						words = rawBlock.paragraphs[0].words,
						numberWidth = 0,
						numberHeight = 0

					
					let numberBlock = words[words.length-1], 
						bbNumber = numberBlock.boundingBox['vertices'],
						p0Number =  [bbNumber[0].x, bbNumber[0].y],
						p1Number =  [bbNumber[1].x, bbNumber[1].y],
						p2Number =  [bbNumber[3].x, bbNumber[3].y],
						p3Number =  [bbNumber[2].x, bbNumber[2].y]
					numberWidth = Math.max(p1Number[0] - p0Number[0], 24)
					numberHeight = Math.max(p2Number[1] - p0Number[1], 24)

					let blockNum = {
						'p0' : p0Number,
						'width' : numberWidth,
						'height' : numberHeight,
						'text' : number, 
						'type' : 'amperage'
					}

					// verify if the number is useful
					if (parseInt(text) % 5 == 0 && parseInt(text) <= 90) {
						amperageBlocks.push(blockNum)
					}

					// create block for the description
					block = {
						'p0' : [block.p0[0], block.p0[1]],
						'width' : block.width - numberWidth,
						'height' : block.height - numberHeight,
						'text' : text, 
						'type' : 'description'
					}

					textBlocks.push(block)
				}

				cleanBlocks = cleanBlocks.concat(amperageBlocks)
				cleanBlocks = cleanBlocks.concat(textBlocks)

				for (let idx in cleanBlocks) {
					cleanBlocks[idx]['id'] = idx
					cleanBlocks[idx]['center'] = [cleanBlocks[idx]['p0'][0] + (cleanBlocks[idx]['width']/2), cleanBlocks[idx]['p0'][1] + (cleanBlocks[idx]['height']/2),]
				}

				return cleanBlocks
			},
			verifyBlocks : function (rawBlocks) {
				console.log('verifying blocks')
				let points = [],
					centers = [],
					types = [],
					ids = [],
					groupedBlocks = [],
					verifiedBlocks = []

				this.amperageBlocks = []
				this.descriptionBlocks = []

				for (let i in rawBlocks) {
					let block = rawBlocks[i]

					points.push(block['p0'][0])
					centers.push(block['center'][0])
					types.push(block['type'])
					ids.push(block['id'])

				}

				// determine if there are 2 or 3 columns
				let n_groups = 3,
					imgCenter = (this.imgWidth * this.widthRatio) / 2,
					toleranceInterval = (this.imgWidth * this.widthRatio) / 5,
					centerBoundaries = [imgCenter - toleranceInterval, imgCenter + toleranceInterval],
					lateralBoundaries = [toleranceInterval, (this.imgWidth * this.widthRatio) - toleranceInterval]

					/*toleranceInterval = (this.imgWidth * this.widthRatio) / 3.5, 
					centerBoundaries = [imgCenter - toleranceInterval, imgCenter + toleranceInterval]*/

				/*
				if (Math.abs(imgCenter - this.median(points)) > toleranceInterval) {
					n_groups = 2
				}
				*/

				if (n_groups == 3) {
					for (let [i, x] of points.entries()) {
						let center = centers[i]
						// if inside tolerance boundary
						if (types[i] == 'amperage') {
							if (center > centerBoundaries[0] && center < centerBoundaries[1]) {
								// is amperage
								let block = rawBlocks[i]
								this.amperageBlocks.push(block)
								verifiedBlocks.push(block)
							}
						} else {
							//if (x < centerBoundaries[0] || x > centerBoundaries[1]) {
							if ((center < lateralBoundaries[0] && x > -10) || (center > lateralBoundaries[1] && center < (this.imgWidth * this.widthRatio)+20)) {
								// is description
								let block = rawBlocks[i]
								block['height'] < 32 ? block['height'] = 32 : block['height'] = block['height']
								block['text'] = `${block['text'][0].toUpperCase()}${block['text'].slice(1)}`
								this.descriptionBlocks.push(block)
								verifiedBlocks.push(block)
							}
						}
					}
				}

				// TODO
				//manage n_groups = 2

				return verifiedBlocks
			},
			linkBlocks : function () {
				console.log('linking blocks')
				let annotatedCanvas = this.$refs.annotationCanvas,
					context = annotatedCanvas.getContext('2d')
				// link amps with descriptions
				let ampsIds = this.amperageBlocks.map(x => {
					return x['id']
				})

				let ampsCoords = this.amperageBlocks.map(x => {
					return x['center']
				})

				let descrCoords = this.descriptionBlocks.map(x => {
					return x['center']
				})

				let groupedItems = [],
					added = []

				// assign description to amp
				for (let i = 0; i < ampsCoords.length; i ++) {
					let x = ampsCoords[i], 
						y = null,
						candidate = null, 
						candidateJ = null,
						currentDist = Infinity

					for (let j = 0; j < descrCoords.length; j ++) {
						y = descrCoords[j]
						
						let dist = this.euclideanDist(x, y)
						if (dist < currentDist) {
							currentDist = dist
							candidate = y
							candidateJ = j
						}
					}

					this.drawLine(context, x, candidate, '#5BAEE1', 3)

					let descrBlock = this.descriptionBlocks[candidateJ],
						descrBlockId = descrBlock['id'],
						ampBlock = this.amperageBlocks[i],
						ampBlockID = this.amperageBlocks[i]['id']

					if (!added.includes(descrBlockId)) {
						groupedItems.push({
							'description' : descrBlock['text'],
							'amperages' : [
								{
									'amperage' : ampBlock['text'],
									'id' : ampBlockID
								}
							],
							'id' : descrBlockId
						})
						added.push(descrBlockId)
					} else {	
						groupedItems[added.indexOf(descrBlockId)]['amperages'].push({
							'amperage' : ampBlock['text'],
							'id' : ampBlockID
						})
					}

				}

				this.groupedBlocks = {'groups' : groupedItems}
			},
			getText : function (paragraphs) {
				return paragraphs.map(paragraph => {
					let wordsContainer = paragraph.words

					let words = wordsContainer.map(w => {
						let symbolsContainer = w.symbols

						let symbols = symbolsContainer.map(s => {
							return s.text
						})

						return(symbols.join(''))

					})

					return(words.join(' ').replace('/\s+$/', ''))

				})[0]
			},
			drawLine : function (context, begin, end, stroke = '#FEDA32', width = 1) {
				if (stroke) {
					context.strokeStyle = stroke
				}

				if (width) {
					context.lineWidth = width
				}

				context.beginPath()
				context.moveTo(...begin)
				context.lineTo(...end)
				context.stroke()

			},
			median : function (arr) {
				let sorted = Array.from(arr).sort((a, b) => a - b),
					middle = Math.floor(sorted.length / 2)

				if (sorted.length % 2 === 0) {
					return (sorted[middle - 1] + sorted[middle]) / 2
				}

				return sorted[middle]

			},
			euclideanDist : function (x, y) {
				let xd = Math.pow((x[0] - y[0]), 2),
					yd = Math.pow((x[1] - y[1]), 2),
					d = Math.sqrt(xd + yd)
				return d
			},
			changeState : function () {
				this.$emit('annotated', this.groupedBlocks)
			}
		}
	}
</script>
<style>

	#labels-container {
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#labels {
		position: relative;
	}

	#json-download-btn {
		position: fixed;
		top: 16px;
		right: 16px;
	}

	@media screen and (max-width: 480px) {
		#labels-container  {
			height: 100%;
		}
	}
</style>