<template>
	<div :id='id'>
		<form class='uploader-container'>
			<input ref='fileLoader' type='file' name='panel-img' accept='image/png' v-on:change='readFile' size='1'/>
			<div id='file-upload'>
				<label for='panel-img'>
					<span v-on:click='clickInput()' id='file-upload-btn'>
						Select a picture
					</span>
				</label>
			</div>
		</form>
	</div>
</template>

<script>
	import {LZWEncoder, NeuQuant, GIFEncoder, encode64} from '../assets/GIFEncoder.js'
	
	export default {
		name: 'FileUploader',
		components : {},
		props : ['id'],
		data(){
			return {
				pngData : null
			}
		}, 
		watch : {
			pngData(newPNG) {
				this.$emit('image', this.pngData)
			}
		},
		methods : {
			clickInput : function () {
				this.$refs.fileLoader.click()
			},
			readFile : function (e) {

				if (e == null) {return}

				console.log('reading file')

				let uploaded = e.target.files[0],
					reader = new FileReader()

				reader.onload = ((f) => {
					return event => {
						this.pngData = event.target.result
					}
				})(uploaded)

				reader.readAsDataURL(uploaded)
			}
		}
	}
	
</script>

<style>
	input[type="file"] {
		display: none;
	}

	#file-upload {
		padding: 16px;
		border: none;
		border-radius: 32px;
		background-color: var(--light-blue);
		color: #FFF;
		cursor: pointer;
		width: 20vw;
		font-weight: 700;
	}

	#file-upload span {
		color: #FFF;
		cursor: pointer;
	}

	@media screen and (max-width: 900px) {
		#file-upload  {
			width: 40vw;
		}
	}
</style>