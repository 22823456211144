<template>
	<div :id='id' :class='{
			"ocr-label" : true, 
			"amperage" : type == "amperage", 
			"description" : type == "description", 
			"mixed" : type == "mixed",
			"group-0" : group == "0",
			"group-1" : group == "1",
			"group-2" : group == "2",
			"group-3" : group == "3"
		}' :type='type' :style='"top: " + y + "px; left: " + x + "px; width: " + width + "px; height: " + height + "px;"'>
		<div class='ocr-text'>{{ text }}</div>
	</div>
</template>
<script>
	export default {
		name: 'OCRLabel',
		components : {},
		props : ['id', 'x', 'y', 'width', 'height', 'text', 'type', 'group'],
	}
</script>
<style>
	.ocr-label {
		border-radius: 4px;
		position: absolute;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px;
	}

	.ocr-text {
		font-size: 8px;
	}

	.amperage {
		border:  3px solid var(--light-blue);
		background-color: rgba(48, 89, 126, 0.8);
	}

	.amperage .ocr-text {
		font-size: 10px;
		color: #FFF; 
	}

	.description {
		border: 3px solid var(--mid-blue);
		background-color: rgba(255, 255, 255, 0.8);
		color: #000;
	}

	@media screen and (max-width: 480px) {
		.ocr-text {
			font-size: .6rem;
		}
	}
</style>