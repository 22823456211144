<template>
	<div :id='id' class='screen access-screen'>
		<LogoHeader/>
		<div class='login-container'>
			<div>
				<input ref='passwordInput' v-on:keyup.enter.prevent='checkPassword' type='password' name='password' autocomplete='off' placeholder='Password'/>
			</div>
			<div v-if='incorrectPassword == true'>
				<p class='password-hint'>
					Incorrect password
				</p>
			</div>
			<div>
				<p class='login-message'>Please enter the password to start</p>
			</div>
			<div class='button-container'>
				<button v-on:click='checkPassword' id='sign-in-btn'>Sign in</button>
			</div>
		</div>
	</div>
</template>

<script>
	import LogoHeader from './LogoHeader.vue'
	export default {
		name: 'AccessScreen',
		components : {
			LogoHeader
		},
		props : ['id'],
		data(){
			return {
				password : 'moixa',
				incorrectPassword : false
			}
		}, 
		methods : {
			checkPassword : function () {
				let pass = this.$refs.passwordInput

				if (pass.value == this.password) {
					console.log('logged')
					this.incorrectPassword = false
					this.$emit('logged', true)
				} else {
					this.incorrectPassword = true
				}
			}
		}
	}
	
</script>

<style>
	#sign-in-btn {
		background-color: var(--mid-blue);
	}

	.password-hint {
		color: var(--light-blue);
	}
	
	.login-container {
		width: 100%;
		background-color: var(--dark-blue);
		height: 85vh;
		position: fixed;
		bottom: 0;
		display: flex;
		flex-direction: column;
		border-radius: 32px 32px 0 0;
		padding: 24px;
	}

	.login-container div {
		padding: 12px;
		text-align: center;
		width: 100%;		
	}

	.login-message {
		color: #FFF;
		font-size: 1.2rem;
	}

	@media screen and (max-width: 480px) {
		.login-container {
			height: 85dvh;
		}
	}
</style>