<template>
	<div :id='id' class='report-screen screen'>
		<!-- SVG -->
		<a id='json-downloader' ref='JSONDowloader'>
			<button id='json-download-btn'>Download JSON</button>
		</a>
		<div class='panel-number'>
			<h2>Panel #1</h2>
		</div>
		<div>
			<img id='intro-icon' src='/intro-picture.svg'>
		</div>
		<ReportEntry v-for='(group, key) in groups' :name='group.description' :amperages='group.amperages'/>
	</div>
</template>
<script>
	import ReportEntry from './ReportEntry.vue'

	export default {
		name: 'ReportScreen',
		components : {
			ReportEntry
		},
		props : ['id', 'groups'],
		mounted () {
			let data = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({groups : this.groups}))}`
			let downloader = this.$refs.JSONDowloader
			
			downloader.setAttribute('href', data)
			downloader.setAttribute('download', 'panel.json')
		}
	}
</script>
<style>
	.panel-number {
		padding-bottom: 16px;
	}

	.screen.report-screen {
		position: absolute;
		top: 12vh;
		height: auto;
		justify-content: left;
		padding-bottom: 5vh;
	}

	@media screen and (max-width: 480px) {
		.screen.report-screen {
			position: absolute;
			top: 12dvh;
			height: auto;
			justify-content: left;
			padding-bottom: 5%;
		}
	}
</style>