<template>
	<div v-on:click='changeState' class='navigator'>
		<img src='/arrow-icon.svg'>
	</div>
</template>
<script>
	const states = ['access', 'start', 'load-image', 'annotate-image', 'visualise']
	export default {
		name: 'StateNavigator',
		props : ['state'],
		methods : {
			changeState : function () {
				this.$emit('newState', states[states.indexOf(this.state) - 1])
			}
		}
	}
</script>
<style>
	.navigator {
		position: fixed;
		top: 32px;
		left: 32px;
		cursor: pointer;
	}
</style>