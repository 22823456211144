<template>
	<AccessScreen v-if='state == "access"' id='access-screen' @logged='logged'/>
	<IntroScreen v-if='state == "start"' id='intro-screen' @started='started'/>
	<ImageSelectionScreen v-if='state == "load-image"' id='image-selection-screen' @image='imageSelected' @imagePNG='savePNG'/>
	<ImageAnnotationScreen v-if='state == "annotate-image"' id='image-annotation-screen' :image='image' :png='png' @annotated='annotated'/>
	<ReportScreen v-if='state == "visualise"' id='report-screen' :groups='json.groups'/>
	<StateNavigator v-if='state != "access" && state != "start"' @newState='goToState' :state='state'/>
</template>

<script>

import StateNavigator from './components/StateNavigator.vue'
import AccessScreen from './components/AccessScreen.vue'
import IntroScreen from './components/IntroScreen.vue'
import ImageSelectionScreen from './components/ImageSelectionScreen.vue'
import ImageAnnotationScreen from './components/ImageAnnotationScreen.vue'
import ReportScreen from './components/ReportScreen.vue'

export default {
	name: 'App',
	components: {
		StateNavigator,
		AccessScreen,
		IntroScreen, 
		ImageSelectionScreen,
		ImageAnnotationScreen,
		ReportScreen
	},
	emits : ['started', 'image', 'annotated'],
	data(){
		return {
			state : 'access', // ['access', start', 'load-image', 'annotate-image', 'visualise']
			image : null,
			png : null,
			json : null
		}
	}, 
	mounted () {
		if (this.isMobile()) {
			//document.documentElement.style.setProperty('--screen-height', `${}px`)
		}
	},
	methods : {
		isMobile : function () {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		},
		goToState : function (state) {
			console.log(`returning to ${state}`)
			this.state = state
		},
		logged : function () {
			console.log('logged')
			this.state = 'start'
		},
		started : function () {
			console.log('started')
			this.state = 'load-image'
		},
		imageSelected : function (image) {
			console.log('image ready')
			this.image = image
			this.state = 'annotate-image'
		},
		annotated : function (groups) {
			console.log(groups)
			this.json = groups
			this.state = 'visualise'
		},
		savePNG : function (png) {
			this.png = png
		}
	}
}
</script>

<style>
</style>
